<template>
  <b-modal
    id="cms-create-game-banner"
    title="New Game Banner"
    no-close-on-backdrop
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          name="image_src"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="createGameBannerForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (createGameBannerForm.imageSrc = value || '')"
            />
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="position"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Position">
            <b-form-select
              id="position"
              v-model="createGameBannerForm.position"
              :state="errors[0] ? false : null"
            >
              <b-form-select-option
                v-for="item of positions"
                :key="item"
                :value="item"
                >{{ item }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <b-form-group label="Has link">
          <b-form-checkbox
            v-model="createGameBannerForm.hasLink"
            name="check-button"
            switch
          >
          </b-form-checkbox>
        </b-form-group>
        <div v-if="createGameBannerForm.hasLink">
          <b-form-group label="External link">
            <b-form-checkbox
              v-model="createGameBannerForm.isExternalLink"
              name="check-button"
              switch
            >
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="createGameBannerForm.isExternalLink"
            label="External link"
          >
            <b-input v-model="createGameBannerForm.externalLinkTo"> </b-input>
          </b-form-group>
          <b-form-group v-else label="Internal link">
            <b-input v-model="createGameBannerForm.internalLinkTo"> </b-input>
          </b-form-group>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      createGameBannerForm: {
        position: 'ALL_TOP',
        imageSrc: '',
        hasLink: false,
        isExternalLink: false,
        externalLinkTo: '',
        internalLinkTo: '',
      },
      positions: [
        'ALL_TOP',
        'ALL_BOTTOM',
        'SLOT_TOP',
        'SLOT_BOTTOM',
        'FISHING_TOP',
        'FISHING_BOTTOM',
        'CASINO_TOP',
        'CASINO_BOTTOM',
        'SPORT_TOP',
        'SPORT_BOTTOM',
        'GRAPH_TOP',
        'GRAPH_BOTTOM',
      ],
    }
  },
  computed: {
    ...mapState({
      isCreatingSiteGameBanners: (state) => state.cms.isCreatingSiteGameBanners,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsGameBanners']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-game-banner'))
      }
    },
    async onSubmit(cb) {
      await this.createCmsGameBanners({
        siteId: this.siteId,
        gameBanners: [this.createGameBannerForm],
      })

      cb()
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-game-banner","title":"New Game Banner","no-close-on-backdrop":""},on:{"ok":_vm.onOk}},[_c('ValidationObserver',{ref:"cmsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"image_src","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.createGameBannerForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.createGameBannerForm.imageSrc = value || ''); }}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"position","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-select',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.createGameBannerForm.position),callback:function ($$v) {_vm.$set(_vm.createGameBannerForm, "position", $$v)},expression:"createGameBannerForm.position"}},_vm._l((_vm.positions),function(item){return _c('b-form-select-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1)],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Has link"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.createGameBannerForm.hasLink),callback:function ($$v) {_vm.$set(_vm.createGameBannerForm, "hasLink", $$v)},expression:"createGameBannerForm.hasLink"}})],1),(_vm.createGameBannerForm.hasLink)?_c('div',[_c('b-form-group',{attrs:{"label":"External link"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.createGameBannerForm.isExternalLink),callback:function ($$v) {_vm.$set(_vm.createGameBannerForm, "isExternalLink", $$v)},expression:"createGameBannerForm.isExternalLink"}})],1),(_vm.createGameBannerForm.isExternalLink)?_c('b-form-group',{attrs:{"label":"External link"}},[_c('b-input',{model:{value:(_vm.createGameBannerForm.externalLinkTo),callback:function ($$v) {_vm.$set(_vm.createGameBannerForm, "externalLinkTo", $$v)},expression:"createGameBannerForm.externalLinkTo"}})],1):_c('b-form-group',{attrs:{"label":"Internal link"}},[_c('b-input',{model:{value:(_vm.createGameBannerForm.internalLinkTo),callback:function ($$v) {_vm.$set(_vm.createGameBannerForm, "internalLinkTo", $$v)},expression:"createGameBannerForm.internalLinkTo"}})],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }